import React, {
  useRef,
  ChangeEvent,
  ReactNode,
  useState,
  createContext,
  useContext,
} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import assets_empty_state from "../assets/assets_empty_state.png";
import "./AssetDropZone.sass";

export interface AssetDropZoneProps {
  children?: ReactNode;
  onPick: (files: File[]) => void;
  isUploading?: boolean;
}

// Drop zone container for uploading assets that can also accept children widgets
export const AssetDropZone: React.FC<AssetDropZoneProps> = (props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const { children, onPick, isUploading } = props;

  // whether the user is dragging files over the drop zone
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const pickFiles = () => {
    fileRef.current?.click();
  };

  const onSelectFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles || selectedFiles.length === 0) return;

    const filesArray = Array.from(selectedFiles);
    onPick(filesArray);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    // need this or the browser will open the file
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);

    if (onPick) {
      const files = e.dataTransfer.files;
      if (files && files.length > 0) {
        const filesArray = Array.from(files);
        onPick(filesArray);
      }
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <AssetContext.Provider value={{ pickFiles }}>
        <div
          className={"drop-zone"}
          onDragEnter={() => setIsDraggingOver(true)}
          onDragLeave={() => setIsDraggingOver(false)}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {isUploading && (
            <div className="drop-zone-uploading-overlay">
              <div>Uploading...</div>
            </div>
          )}
          {isDraggingOver ? (
            <div className={"drop-zone-active"}>
              <img src={assets_empty_state} alt="empty assets" />
              <span>Release to upload assets</span>
            </div>
          ) : (
            <div className={"drop-zone-body"}>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileRef}
                onChange={onSelectFiles}
                multiple
              />
              {children ? (
                children
              ) : (
                <div className={"drop-zone-placeholder"} onClick={pickFiles}>
                  <img src={assets_empty_state} alt="empty assets" />
                  <span>
                    Drop images, videos, or other assets here or click to select
                    from file system
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </AssetContext.Provider>
    </DndProvider>
  );
};

// enable the children to access openFileUpload
const AssetContext = createContext<{ pickFiles: () => void } | undefined>(
  undefined,
);

export const useAssetUpload = () => {
  const context = useContext(AssetContext);
  if (!context) {
    throw new Error("pickFiles must be used within a AssetDropZone");
  }
  return context;
};
