import { useRef, type FC, ChangeEvent } from "react";
import type { DropTargetMonitor } from "react-dnd";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

import { ReactComponent as IconAdd } from "../../assets/icon_plus.svg";

import "../../styles/components/upload-modal.sass";
export interface UploadProps {
  onDrop: (files: File[]) => void;
  displayText: string;
  isUploading: boolean;
  fileTypes?: string[];
  uploadType?: string;
}

export const Upload: FC<UploadProps> = (props) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const { onDrop, isUploading, fileTypes, displayText, uploadType } = props;

  const openFileUpload = () => {
    fileRef.current?.click();
  };

  const onSelectFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles || selectedFiles.length === 0) return;

    const filesArray = Array.from(selectedFiles);
    onDrop(filesArray);
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: File[] }) {
        if (onDrop) {
          onDrop(item.files);
        }
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop],
  );

  const isActive = canDrop && isOver;

  if (isUploading) {
    return (
      <div className="upload-asset">
        <p>Uploading...</p>
      </div>
    );
  }

  return (
    <div
      ref={drop}
      className={uploadType === "font" ? "upload-font-asset" : "upload-asset"}
      onClick={openFileUpload}
    >
      {isActive ? (
        "Release to drop"
      ) : (
        <>
          <IconAdd className="upload-asset__icon" />
          <input
            type="file"
            ref={fileRef}
            onChange={onSelectFiles}
            multiple={uploadType === "font" ? false : true}
            accept={fileTypes?.join(",")}
            hidden
          />
          <p>{displayText}</p>
        </>
      )}
    </div>
  );
};
