import { equals, head } from "ramda";
import {
  Document,
  isMap,
  isPair,
  isSeq,
  Node,
  Pair,
  parseDocument,
  Scalar,
  stringify,
  visit,
  YAMLMap,
} from "yaml";
import { create } from "zustand";
import {
  NodeCategory,
  TreeItemWidgetPayload,
  TreeNode,
} from "../containers/NavigatorPanel/TreeNode";
import {
  buildNodesInView,
  buildScreenAPIs,
  buildScreenBody,
  buildScreenCodeBlock,
  buildScreenCustomWidgets,
  buildScreenRoot,
  hasViewGroup,
} from "../utils/treeUtils";
import { SchemaStore } from "./useSchemas";
import { ArtifactType } from "../pages/EditorPage";
import { addChildWidget, getCustomWidgetNodes } from "../utils/schemaUtils";
import { TreeItemPayload } from "../containers/NavigatorPanel/TreeNode";
import { removeNodeFromParent } from "../utils/docUtils";

// Custom Widgets and APIs are Pairs
// Widgets can be Maps or Scalars
export type YAMLDocNode = Node | Pair | Document<Node>;

export type SetSelectedNode = (
  node: YAMLDocNode | undefined,
  parent: YAMLMap | null,
  ancestry: readonly YAMLDocNode[],
) => void;

export interface YamlDocState {
  nonEditorChanges: number;
  doc: Document | null;
  docTreeStore: TreeStore | null | undefined;
  appCustomWidgets: string[];
  artifactType: ArtifactType | null;
  schemaStore: SchemaStore | null;

  selectedNode?: YAMLDocNode | undefined;
  selectedNodeParent: YAMLMap | null;
  setSelectedNode: SetSelectedNode;
  isPropertyPanelOpen: boolean;
  setDoc: (
    artifactType: ArtifactType,
    yaml: string | null,
    schemas: SchemaStore | null,
  ) => void;
  updateDocContent: (
    artifactType: ArtifactType,
    yaml: string,
    schemas: SchemaStore | null,
    forceChange?: boolean,
  ) => void;
  insertRootNode: (nodeName: string) => void;
  updateNode: (node: YAMLDocNode) => void;
  insertNode: (
    node: YAMLDocNode,
    parentNode: YAMLDocNode,
    siblingNode: YAMLDocNode,
    offset: number,
  ) => void;
  addNodeByAction: (
    parentNode: YAMLMap,
    node: YAMLDocNode,
    action: NodeAction,
    payload?: TreeItemPayload,
  ) => void;
  addNode: (parentNode: YAMLDocNode, node: YAMLDocNode) => void;
  removeNode: (node: YAMLDocNode) => void;
  removeNodeFromParent: (parent: Node, node: Node | Pair) => void;
  dispatchVisualEditorChanges: () => void;
  setIsPropertyPanelOpen: (isOpen: boolean) => void;
  onAppCustomWidgetsChanged: (appCustomWidgets: string[]) => void;
  moveNode: (
    newNode: Pair,
    parentNode: YAMLMap,
    dragNode: Pair,
    dropNode: Pair,
    position: number,
  ) => void;
}

export interface TreeStore {
  root: TreeNode[] | null;
  body: TreeNode[] | null;
  customWidgets: TreeNode[] | null;
  api: TreeNode[] | null;
  codeBlock: TreeNode[] | null;

  widgetList: string[];
  customWidgetList: string[];
  viewGroup: TreeNode[] | null;
  header: TreeNode[] | null;
  footer: TreeNode[] | null;
}

export enum NodeAction {
  // add a widget to the parent's "children"
  AddChild = "addChild",

  // set the body widget for the Screen root or the Custom Widget root (defined on its own)
  // This is special since the YAML might not contain anything except the tag "View" or "Widget"
  SetRootBody = "setRootBody",

  // set the body widget for the widgets defined in a screen
  SetWidgetBody = "setWidgetBody",

  // set a widget for this node (this happens when the widget selection is on the dialog)
  SetDialogWidget = "setDialogWidget",

  SetTreeItemWidget = "setTreeItemWidget",
}

export const useYamlDoc = create<YamlDocState>()((set) => ({
  nonEditorChanges: 0,
  doc: null,
  docTreeStore: undefined,
  appCustomWidgets: [],
  artifactType: null,
  schemaStore: null,

  selectedNode: undefined,
  selectedNodeParent: null,
  isPropertyPanelOpen: false,
  setSelectedNode: (node, parent) =>
    set(() => {
      return {
        selectedNode: node,
        selectedNodeParent: parent,
      };
    }),
  setDoc: (artifactType: ArtifactType, yaml, schemas: SchemaStore | null) => {
    set((state) => {
      if (schemas == null) return state;
      try {
        const doc = processInitialYaml(yaml, artifactType);

        // const customWidgetNodes = getCustomWidgetNodes(doc?.contents);
        const docTreeStore = updateTreeStore(
          doc,
          schemas,
          artifactType,
          state.appCustomWidgets,
        );
        return {
          nonEditorChanges: 0,
          doc,
          docTreeStore,

          // save these for later since they don't change for this document
          artifactType: artifactType,
          schemaStore: schemas,
        };
      } catch (e) {
        return state;
      }
    });
  },
  updateDocContent: (
    artifactType: ArtifactType,
    yaml,
    schemas: SchemaStore | null,
    forceChange?: boolean,
  ) =>
    set((state) => {
      try {
        const doc = parseDocument(yaml, { keepSourceTokens: true });
        // Only update on structural changes to doc
        if (equals(doc.toJS(), state.doc?.toJS()) || doc.errors.length > 0) {
          console.log(doc.errors);
          return state;
        }
        const docTreeStore = updateTreeStore(
          doc,
          schemas,
          artifactType,
          state.appCustomWidgets,
        );
        return {
          nonEditorChanges: !forceChange ? 0 : state.nonEditorChanges + 1,
          doc,
          docTreeStore,
        };
      } catch (e) {
        console.log(e);
        return state;
      }
    }),
  insertRootNode: (nodeName) =>
    set((state) => {
      let doc = state.doc;
      if (!doc || !isMap(doc.contents)) {
        doc = parseDocument(`${nodeName}:`, { keepSourceTokens: true });
      } else if (!doc.has(nodeName)) {
        doc.add(new Pair(new Scalar(nodeName), {}));
      }
      let docTreeStore = null;
      if (state.schemaStore && state.artifactType) {
        docTreeStore = updateTreeStore(
          doc,
          state.schemaStore,
          state.artifactType,
          state.appCustomWidgets,
        );
      }
      return {
        nonEditorChanges: state.nonEditorChanges + 1,
        doc: doc,
        docTreeStore: docTreeStore ?? state.docTreeStore,
      };
    }),
  updateNode: () =>
    set((state) => {
      let docTreeStore = null;
      if (state.schemaStore && state.artifactType) {
        docTreeStore = updateTreeStore(
          state.doc,
          state.schemaStore,
          state.artifactType,
          state.appCustomWidgets,
        );
      }
      return {
        docTreeStore: docTreeStore ?? state.docTreeStore,
        nonEditorChanges: state.nonEditorChanges + 1,
      };
    }),
  insertNode: (
    node: YAMLDocNode,
    parentNode: YAMLDocNode,
    siblingNode: YAMLDocNode,
    offset: number,
  ) =>
    set((state) => {
      if (!isSeq(parentNode) || !isPair(node)) {
        return state;
      }

      let indexToInsert = 0;
      visit(parentNode, {
        Map: (key, visitNode) => {
          if (head(visitNode.items) === siblingNode) {
            indexToInsert = Math.min(
              Math.max(0, Number(key) + offset),
              parentNode.items.length,
            );
            return visit.BREAK;
          }
          return visit.SKIP;
        },
      });

      const entry = new YAMLMap();
      entry.add(node);
      parentNode.items.splice(indexToInsert, 0, entry);
      return {
        nonEditorChanges: state.nonEditorChanges + 1,
      };
    }),
  addNodeByAction: (
    parentNode: YAMLMap,
    node: YAMLDocNode,
    action: NodeAction,
    payload?: TreeItemPayload,
  ) =>
    set((state) => {
      let hasStructuralChanges = false;
      // add the new node under the parent's "children" sequence
      if (action == NodeAction.AddChild) {
        addChildWidget(parentNode, node);
        hasStructuralChanges = true;
      }
      // set the body widget (override if exists)
      else if (action == NodeAction.SetRootBody) {
        // Note that the YAML might not contain anything except the tag "View" or "Widget"
        // so the parentNode here is the document root
        let bodyParent = null;
        if (state.artifactType == ArtifactType.screen) {
          bodyParent = parentNode.get("View");
          if (!(bodyParent instanceof YAMLMap)) {
            bodyParent = new YAMLMap();
            parentNode.set("View", bodyParent);
          }
        } else if (state.artifactType == ArtifactType.widget) {
          bodyParent = parentNode.get("Widget");
          if (!(bodyParent instanceof YAMLMap)) {
            bodyParent = new YAMLMap();
            parentNode.set("Widget", bodyParent);
          }
        }

        // now add the widget to the body
        if (bodyParent) {
          bodyParent.set("body", node);
          hasStructuralChanges = true;
        }
      } else if (action == NodeAction.SetWidgetBody) {
        parentNode.set("body", node);
        hasStructuralChanges = true;
      } else if (action == NodeAction.SetTreeItemWidget) {
        if (payload instanceof TreeItemWidgetPayload && payload.property) {
          parentNode.set(new Scalar(payload.property), node);
          hasStructuralChanges = true;
        }
      }

      if (hasStructuralChanges) {
        // the tree will change
        let docTreeStore = null;
        if (state.schemaStore && state.artifactType) {
          docTreeStore = updateTreeStore(
            state.doc,
            state.schemaStore,
            state.artifactType,
            state.appCustomWidgets,
          );
        }
        return {
          docTreeStore: docTreeStore ?? state.docTreeStore,
          nonEditorChanges: state.nonEditorChanges + 1,
        };
      }
      return state;
    }),
  addNode: (parent: YAMLDocNode, node: YAMLDocNode) =>
    set((state) => {
      let didAdd = false;
      if (isSeq(parent)) {
        parent.add(node);
        didAdd = true;
      }
      if (isMap(parent) && isPair(node)) {
        parent.items.push(node);
        didAdd = true;
      }
      if (isPair(parent)) {
        parent.value = node;
        didAdd = true;
      }
      if (didAdd) {
        let docTreeStore = null;
        if (state.schemaStore && state.artifactType) {
          docTreeStore = updateTreeStore(
            state.doc,
            state.schemaStore,
            state.artifactType,
            state.appCustomWidgets,
          );
        }
        return {
          docTreeStore: docTreeStore ?? state.docTreeStore,
          nonEditorChanges: state.nonEditorChanges + 1,
        };
      }
      return state;
    }),
  removeNode: (nodeToRemove: YAMLDocNode) =>
    set((state) => {
      const doc = state.doc;
      let didRemove = false;
      visit(doc, (key, node) => {
        if (isMap(node)) {
          if (node.items.length === 1 && head(node.items) === nodeToRemove) {
            didRemove = true;
            return visit.REMOVE;
          }
        }
        if (nodeToRemove === node) {
          didRemove = true;
          return visit.REMOVE;
        }
      });

      if (didRemove) {
        let docTreeStore = null;
        if (state.schemaStore && state.artifactType) {
          docTreeStore = updateTreeStore(
            state.doc,
            state.schemaStore,
            state.artifactType,
            state.appCustomWidgets,
          );
        }
        return {
          nonEditorChanges: state.nonEditorChanges + 1,
          docTreeStore: docTreeStore ?? state.docTreeStore,
        };
      }
      return state;
    }),
  removeNodeFromParent: (parent: Node, nodeToRemove: Node | Pair) =>
    set((state) => {
      const didRemove = removeNodeFromParent(parent, nodeToRemove);
      if (didRemove) {
        return {
          nonEditorChanges: state.nonEditorChanges + 1,
        };
      }
      return state;
    }),
  dispatchVisualEditorChanges: () =>
    set((state) => ({ nonEditorChanges: state.nonEditorChanges + 1 })),
  setIsPropertyPanelOpen: (isOpen: boolean) =>
    set(() => ({ isPropertyPanelOpen: isOpen })),
  onAppCustomWidgetsChanged: (appCustomWidgets) => {
    set((state) => {
      // update the tree as we might need to show the app's custom widgets
      if (state.doc && state.schemaStore && state.artifactType) {
        const docTreeStore = updateTreeStore(
          state.doc,
          state.schemaStore,
          state.artifactType,
          appCustomWidgets,
        );

        return { docTreeStore, appCustomWidgets };
      }
      return { appCustomWidgets };
    });
  },
  moveNode: (
    newNode: Pair,
    parentNode: YAMLMap,
    dragNode: Pair,
    dropNode: Pair,
    position: number,
  ) =>
    set((state) => {
      state.insertNode(newNode, parentNode, dropNode, position);
      state.removeNode(dragNode);
      return {
        nonEditorChanges: state.nonEditorChanges + 1,
      };
    }),
}));

// processing the initial YAML (when we first open a screen or a widget)
// Since empty widget definition is difficult to handle,
// we'll always ensure the widget root node is always there
const processInitialYaml = (
  yaml: string | null,
  artifactType: ArtifactType,
): Document | null => {
  const processedDoc = yaml
    ? parseDocument(yaml, { keepSourceTokens: true })
    : null;

  // empty widget definition is difficult to handle, so we'll always make sure
  // the Widget's root node is always there.
  // The key thing here is to keepSourceToken intact so
  // we always parse the document from a YAML string
  if (artifactType === ArtifactType.widget) {
    if (!processedDoc || !isMap(processedDoc.contents)) {
      return parseDocument("Widget:", { keepSourceTokens: true });
    } else if (!processedDoc.has("Widget")) {
      // this seems weird to manipulate the document, then parse it again
      // but it is to keep the source tokens intact
      processedDoc.set(new Scalar("Widget"), new YAMLMap());
      const updatedYaml = stringify(processedDoc);
      return parseDocument(updatedYaml, { keepSourceTokens: true });
    }
  }
  return processedDoc;
};

const updateTreeStore = (
  doc: Document | null,
  schemas: SchemaStore | null,
  artifactType: ArtifactType,
  appCustomWidgets: string[],
): TreeStore | null => {
  if (doc && isMap(doc.contents)) {
    // whitelisted widgets include framework and custom widgets (app-level and screen-level)
    const platformWidgets = schemas?.widgetNames ?? new Set();
    const screenCustomWidgetNodes = getCustomWidgetNodes(doc.contents);
    const allCustomWidgets = [
      ...appCustomWidgets,
      ...screenCustomWidgetNodes.map((node) => node.value as string),
    ].filter((widget) => widget != null);

    const whitelistedWidgets = new Set([
      ...allCustomWidgets,
      ...platformWidgets,
    ]);
    const containerNames = schemas?.containerNames ?? new Set();

    return {
      root: buildScreenRoot(artifactType, doc.contents),
      body: buildScreenBody(
        artifactType,
        doc.contents,
        whitelistedWidgets,
        containerNames,
        schemas?.widgetChildrenOverrides ?? new Map(),
        schemas?.treeWidgetMapping ?? new Map(),
      ),
      customWidgets: buildScreenCustomWidgets(
        doc.contents,
        screenCustomWidgetNodes,
        whitelistedWidgets,
        containerNames,
        schemas?.widgetChildrenOverrides ?? new Map(),
        schemas?.treeWidgetMapping ?? new Map(),
      ),
      api: buildScreenAPIs(doc.contents),
      codeBlock: buildScreenCodeBlock(doc.contents),
      widgetList: Array.from(platformWidgets),
      customWidgetList: allCustomWidgets,
      viewGroup: hasViewGroup(artifactType, doc.contents) ? [] : null,
      header: buildNodesInView(doc.contents, NodeCategory.Header) ?? null,
      footer: buildNodesInView(doc.contents, NodeCategory.Footer) ?? null,
    };
  }
  return null;
};
