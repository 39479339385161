import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { Popover, Tooltip } from "antd";
import "./ItemPicker.sass";
import { RemixIcon } from "../Widgets";
import { getTreeIcon } from "../../utils/treeUtils";
import { camelCaseToWords } from "../../containers/WidgetPropertyPanel/utils/propertyPanelUtils";
import { useYamlDoc } from "../../hooks/useYamlDoc";
import { useSchemas } from "../../hooks/useSchemas";
import { List, Grid, ListItem, ListSubheader } from "@mui/material";
import "./ItemPicker.sass";
import useKeyboardNavigationGrid from "../../hooks/useKeyboardNavigationGrid";

interface Item {
  icon?: React.ReactNode;
  label: string;
  value: string;
  description?: string;
}

interface Group {
  icon?: React.ReactNode;
  label: string;
  items: Item[];
}

// interface for WidgetPicker/ActionPicker
export interface ItemPickerActionProps {
  onItemSelect: (itemValue: string) => void;
  trigger?: React.ReactNode;
}

// Widget Picker
export const WidgetItemPicker: React.FC<ItemPickerActionProps> = ({
  onItemSelect,
  trigger,
}) => {
  const { docTreeStore } = useYamlDoc();
  const { widgetGroups } = useSchemas();

  // append custom widgets to the end of widget groups
  const groups = Array.from(widgetGroups);
  if (docTreeStore?.customWidgetList.length) {
    groups.push([
      "Custom Widgets",
      docTreeStore.customWidgetList.map((name) => ({ name: name })),
    ]);
  }

  return (
    <ItemPicker
      onItemSelect={onItemSelect}
      trigger={
        trigger ?? (
          <div className={"widget-picker-trigger-label-placeholder"}>
            <RemixIcon name={"layout-masonry-line"} style={{ fontSize: 18 }} />
            Select a widget
          </div>
        )
      }
      groups={Array.from(groups).map(([category, widgets]) => ({
        label: category.toUpperCase(),
        items: widgets.map((widget) => ({
          icon: getTreeIcon(widget.name, 24), // Assuming getTreeIcon(name, size) is a valid function
          label: camelCaseToWords(widget.name), // Assuming camelCaseToWords(name) is a function to format widget names
          value: widget.name,
          description: widget.description,
        })),
      }))}
    />
  );
};

export const ActionItemPicker: React.FC<ItemPickerActionProps> = ({
  onItemSelect,
  trigger,
}) => {
  const { actionSchemaMap, actionGroups } = useSchemas();
  return (
    <ItemPicker
      onItemSelect={onItemSelect}
      trigger={
        trigger ?? (
          <div className={"action-builder-field-selector"}>
            <span>
              <RemixIcon name={"slideshow-3-line"} />
            </span>
            <span> Select an Action</span>
          </div>
        )
      }
      items={Array.from(actionSchemaMap.keys()).map((key) => {
        return {
          label: camelCaseToWords(key),
          value: key,
        };
      })}
      groups={Array.from(actionGroups).map(([category, actions]) => ({
        label: category.toUpperCase(),
        items: actions.map((action) => ({
          icon: getTreeIcon(action.name, 24), // Assuming getTreeIcon(name, size) is a valid function
          label: camelCaseToWords(action.name), // Assuming camelCaseToWords(name) is a function to format widget names
          value: action.name,
          description: action.description,
        })),
      }))}
    />
  );
};

interface ItemPickerProps extends ItemPickerActionProps {
  items?: Item[];
  groups?: Group[];
}

// Generic Item Picker
const ItemPicker: React.FC<ItemPickerProps> = ({
  trigger,
  groups,
  onItemSelect,
}) => {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const handleSelect = useCallback(
    (value: string) => {
      onItemSelect(value);
      setVisible(false);
    },
    [onItemSelect],
  );

  const allItems = groups?.flatMap((group) => group.items);

  const [activeIndex, handleKeyDown] = useKeyboardNavigationGrid(groups ?? []);

  const filteredGroups = useMemo(() => {
    const searchLower = search.toLowerCase();
    return (
      groups
        ?.map((group) => ({
          ...group,
          items: group.items.filter(
            (item) =>
              item.label.toLowerCase().includes(searchLower) ||
              item.value.toLowerCase().includes(searchLower),
          ),
        }))
        .filter((group) => group.items.length > 0) ?? []
    );
  }, [search, groups]);

  const content = (
    <div
      className={"item-picker-body"}
      onKeyDown={(e) => {
        handleKeyDown(e as any);
        if (e.key === "Escape") {
          setVisible(false);
        } else if (e.key === "Enter") {
          if (activeIndex !== null) {
            const selectedValue = allItems?.[activeIndex]?.value;
            if (selectedValue) {
              handleSelect(selectedValue);
            }
          }
        }
      }}
    >
      <div className={"item-picker-body-title"}>Select an entry</div>
      <div className={"item-picker-body-search"}>
        <input
          type={"search"}
          ref={inputRef}
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <List ref={listRef}>
        {filteredGroups?.map((group, groupIndex) => (
          <React.Fragment key={group.label}>
            <ListSubheader className={"item-picker-body-group"}>
              {group.label}
            </ListSubheader>
            <Grid
              container
              spacing={"10px"}
              className={"item-picker-body-items"}
            >
              {group.items.map((item, itemIndex) => {
                const globalIndex =
                  filteredGroups
                    .slice(0, groupIndex)
                    .reduce((acc, g) => acc + g.items.length, 0) + itemIndex;
                return (
                  <Grid item xs={4} key={item.value}>
                    <Tooltip title={item.description} mouseEnterDelay={0.5}>
                      <ListItem
                        className={"item-picker-body-item"}
                        onClick={() => handleSelect(item.value)}
                        tabIndex={0}
                        data-index={globalIndex}
                      >
                        {item.icon && <span>{item.icon}</span>}
                        <div>{item.label}</div>
                      </ListItem>
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  useEffect(() => {
    if (visible) {
      setSearch("");
    }
  }, [visible]);

  useEffect(() => {
    if (activeIndex !== null && listRef.current) {
      const gridItem = listRef.current.querySelector(
        `[data-index='${activeIndex}']`,
      ) as HTMLElement;
      if (gridItem) {
        gridItem.focus();
      }
    }
  }, [activeIndex, listRef]);

  return (
    <Popover
      overlayClassName="item-picker"
      open={visible}
      onOpenChange={setVisible}
      content={content}
      trigger="click"
      placement="right"
      afterOpenChange={(open) => {
        if (open) {
          inputRef.current?.focus();
        }
      }}
    >
      <div className={"item-picker-trigger"}>{trigger}</div>
    </Popover>
  );
};
