import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { useQueryClient } from "react-query";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";

// Styles
import "../styles/app-assets.sass";

// Components
import { Upload } from "../components/Upload/Upload";
import AssetsCard from "../components/AppAssets/AssetsCard";
import AssetsActionButtons from "../components/AppAssets/AssetsActionButtons";

import { useAppContext } from "./AppPagesWrapper";
import { uploadFont } from "../hooks/useAPIs";

// Assets
import assets_empty_state from "../assets/assets_empty_state.png";
import Modal from "../components/Modal";
import FontModalContent from "../components/AppAssets/FontModalContent";
import { convertFileToBase64String } from "../utils/Util";
import { uploadAssets } from "../utils/uploadUtils";

type ModalTypes = "Asset" | "Font" | null;

const AppAssets: React.FunctionComponent = () => {
  const { app, isAppReadOnly } = useAppContext();
  const queryClient = useQueryClient();
  const [uploadTrigger, setUploadTrigger] = useState<ModalTypes>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    document.title = app.name + " assets";
  });

  const handleAssetsUpload = () => {
    setUploadTrigger("Asset");
  };

  const handleFontsUpload = () => {
    setUploadTrigger("Font");
  };

  const onCloseTrigger = () => {
    setUploadTrigger(null);
    setIsUploading(false);
  };

  const onUploadAssets = async (items: File[]) => {
    if (items.length === 0) return;
    setIsUploading(true);

    const success = await uploadAssets(app.id, items);
    if (success) {
      toast.success(`Assets uploaded successfully.`, {
        position: "top-right",
        type: toast.TYPE.SUCCESS,
        theme: "dark",
      });
      await queryClient.invalidateQueries(["app", app.id]);
    } else {
      toast.error(`Failed to upload assets.`, {
        position: "top-right",
        type: toast.TYPE.ERROR,
        theme: "dark",
      });
    }
    setIsUploading(false);
    onCloseTrigger();
  };

  const onUploadFont = async (
    fileData: File[],
    fontFamily: string,
    fontWeight: string,
    fontStyle: string,
    fontType: string,
  ) => {
    try {
      if (fileData === null)
        return toast.error("Please select a file", {
          position: "top-right",
          type: toast.TYPE.ERROR,
          theme: "dark",
        });
      setIsUploading(true);
      const base64 = await convertFileToBase64String(fileData[0]);
      await uploadFont(
        app.id,
        fileData[0].name,
        base64,
        fontFamily,
        fontWeight,
        fontStyle,
        fontType === "Icon font" ? "icon" : "text",
      );
      toast.success(`Font uploaded successfully.`, {
        position: "top-right",
        type: toast.TYPE.SUCCESS,
        theme: "dark",
      });
      queryClient.invalidateQueries(["app", app.id]);
    } catch (error) {
      toast.error(`Failed to upload font.`, {
        position: "top-right",
        type: toast.TYPE.ERROR,
        theme: "dark",
      });
    } finally {
      onCloseTrigger();
    }
  };

  const assetModalContent = (
    <Upload
      key={`upload-${app.id}`}
      displayText="Drop images, videos, or other assets here 
      or click to select from file system"
      onDrop={onUploadAssets}
      isUploading={isUploading}
    />
  );

  const fontModalContent = (
    <FontModalContent
      appId={app.id}
      isUploading={isUploading}
      onUploadFont={onUploadFont}
    />
  );

  return (
    <div className="screen-content">
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>
        <span>/</span>
        <Link to={`/app/${app?.id}/screens`}>{app?.name}</Link>
      </div>

      <div className="page-header">
        <h1>Assets</h1>
        <div className="page-actions">
          {!isAppReadOnly && (
            <div className="assets-actions assets-actions--dense">
              <AssetsActionButtons
                handleAssetsUpload={handleAssetsUpload}
                handleFontsUpload={handleFontsUpload}
              />
            </div>
          )}
        </div>
      </div>

      {(app.assets && app.assets?.length > 0) ||
      (app.fonts && app.fonts?.length > 0) ? (
        <div className="screen-cards-ct">
          {app.assets && app.assets?.length > 0 && (
            <>
              {app.assets.map((asset) => (
                <AssetsCard asset={asset} key={asset.id} />
              ))}
            </>
          )}
          {app.fonts && app.fonts?.length > 0 && (
            <>
              {app.fonts.map((font) => (
                <AssetsCard asset={font} key={font.id} assetType="font" />
              ))}
            </>
          )}
        </div>
      ) : (
        <div className="empty-content">
          <div className="empty-assets">
            <img src={assets_empty_state} alt="empty assets" />
            <h2 className="empty-assets__text">
              Add images, videos, fonts, and other assets to use in your app.
            </h2>
            <div className="assets-actions assets-actions--normal">
              <AssetsActionButtons
                handleAssetsUpload={handleAssetsUpload}
                handleFontsUpload={handleFontsUpload}
              />
            </div>
          </div>
        </div>
      )}

      <DndProvider backend={HTML5Backend}>
        <Modal
          isModalDisplayed={!!uploadTrigger}
          onHide={onCloseTrigger}
          headerText={`Add ${uploadTrigger === "Font" ? "fonts" : "assets"}`}
          modalContent={
            uploadTrigger === "Font" ? fontModalContent : assetModalContent
          }
        />
      </DndProvider>
    </div>
  );
};
export default AppAssets;
