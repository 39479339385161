import { JSONSchema7 } from "json-schema";
import { isEmpty, path } from "ramda";
import clone from "fast-copy";
import { visit, Node, isPair, isMap } from "yaml";

import { YAMLDocNode } from "../../../hooks/useYamlDoc";
import { retrieveSchema, RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";

// our custom JSONSchema7 with custom tags
export interface CustomJSONSchema7 extends JSONSchema7 {
  // render a property with specific UI widgets
  uiType?: string;
  // most fields are toggleable between expression and their native UI
  // set this to False to not allow toggling to Expression mode
  expressionToggleable?: boolean;
  // placeholder used by Input widget
  uiPlaceholder?: string;

  // render a property group (i.e. type: object) with specific template
  groupDisplay?: GroupDisplay;
  // full expand a node and disable expand/collapse functionality
  groupCollapsible?: boolean;

  // the name of the doc topic e.g. 'item-template', which will be linked to our doc URL 'https://docs.ensembleui.com/doc/item-template'
  docTopic?: string;

  // for default value (but we can't use "default" as typeahead will autofill in)
  defaultValue?: string;

  // for oneOf (with const/description), adding an icon to all entries will
  // present the user with an Select field with icons laying out in a row (vs the regular Dropdown)
  icon?: string;

  deprecated?: boolean;

  // how to find the children instead of the default "children" property
  childrenOverride?: string;
  childrenOverrideSubtitle?: string;

  // to move a child widget from PropertyPanel to the tree. The label is used
  // to show the placeholder when the widget has not been set.
  treeItemWidgetLabel?: string;

  // the properties keys to render their content as the preview (currently only used for Action's anchor)
  previewShortKey?: string;
  previewLongKey?: string;

  // category to group widgets/actions
  category?: string;
}

export interface CustomUIField {
  showLabelOverride?: boolean;
}

// doesn't need all the types, just the ones that we use often so there's no typo
export enum UiType {
  action = "action",
  widget = "widget",
}

// by default a group (essentially Object) is rendered with a header that can expand/collapse the body
export enum GroupDisplay {
  // show a vertical line to designate a group item (think Slack card with a vertical bar on the left of the card)
  card = "card",
  // don't show the header, essentially flatten the children into rows
  flatten = "flatten",
}

// get the default value based on the schema type
export const getDefaultValue = (schema: RJSFSchema) => {
  switch (schema.type) {
    case "array":
      return [];
    case "boolean":
      return false;
    case "null":
      return null;
    case "number":
      return 0;
    case "object":
      return {};
    case "string":
      return "";
    default:
      // special handling for Action as the value (e.g. Event handler when calling a widget)
      if (
        schema.additionalProperties &&
        (schema.additionalProperties as JSONSchema7).$ref === "#/$defs/Action"
      ) {
        // TODO: empty object should work too but the ActionTreeBuilder makes the
        //  incorrect assumption that an Action should either be a Scalar or a Map with a single child
        return null;
      }
      return "";
  }
};

export const getWidgetId = (node: YAMLDocNode): string | null => {
  if (isPair(node) && isMap(node.value)) {
    // should probably check if this is a widget?
    return node.value.get("id") as string;
  }
  return null;
};

export const getFormDataValues = (
  node: YAMLDocNode | undefined,
  nodeKey: string | undefined,
  activeCategory?: string,
) => {
  if (!node || !nodeKey) return null;
  const duplicateNode = clone(node);
  visit(duplicateNode as Node, {
    Scalar(key, node) {
      if (node.format === "HEX") node.value = node.source;
    },
  });
  const formData = duplicateNode?.toJSON();
  if (activeCategory === "Styles") {
    return path([nodeKey, "styles"], formData);
  }
  // normalize data to empty object if null (so form internally don't dispatch
  // changes between null and empty object)
  return formData[nodeKey] ?? {};
};

export const updateNodeWithHex = (node: YAMLDocNode | undefined) => {
  visit(node as Node, {
    Scalar(key, node) {
      if (typeof node.value === "string") {
        if (isHex(node.value)) {
          node.source = node.value;
          // node.value = parseInt(node.value, 16);
          node.format = "HEX";
        }
      }
    },
  });
  return node;
};

const isHex = (value: string) => {
  const hexPattern = /^0x[0-9A-Fa-f]+$/;
  return hexPattern.test(value);
};

// resolve all the $refs so we can iterate over the properties
export const getSchemaProperties = (schema: JSONSchema7): JSONSchema7 => {
  return retrieveSchema(customizeValidator(), schema, schema);
};

export const getSubschema = (schema: JSONSchema7, category?: string) => {
  if (category === "Styles") {
    return getStyleSchema(schema);
  } else if (category === "Actions") {
    return getActionSchema(schema);
  }
  return getSettingsSchema(schema);
};

const getStyleSchema = (schema: JSONSchema7): JSONSchema7 | undefined => {
  const schemaProps = getSchemaProperties(schema);
  const maybeStyleSchema = path<JSONSchema7>(
    ["styles"],
    schemaProps.properties,
  );
  if (!maybeStyleSchema) {
    return;
  }

  const workingSchema = clone(maybeStyleSchema);
  workingSchema.$defs = clone(schema.$defs);
  return workingSchema;
};

const getActionSchema = (schema: JSONSchema7): JSONSchema7 | undefined => {
  const schemaProps = getSchemaProperties(schema);
  const actions = Object.entries(schemaProps.properties!).filter(
    ([key, value]) => {
      return (
        (value as CustomJSONSchema7)?.uiType === "action" ||
        key.endsWith("Haptic")
      );
    },
  );
  if (isEmpty(actions)) {
    return;
  }
  const workingSchema = clone(schema);
  delete workingSchema.allOf;
  delete workingSchema.properties;
  workingSchema.properties = Object.fromEntries(actions);
  return workingSchema;
};

export const getSettingsSchema = (
  schema: JSONSchema7,
  shouldRemoveId?: boolean,
): JSONSchema7 | undefined => {
  const schemaProps = getSchemaProperties(schema);
  const settingsProps = Object.entries(schemaProps.properties!).filter(
    ([key, value]) => {
      return (
        key !== "styles" &&
        (value as CustomJSONSchema7)?.uiType !== "action" &&
        !key.endsWith("Haptic") &&
        (!shouldRemoveId || key !== "id")
      );
    },
  );
  if (isEmpty(settingsProps)) {
    return;
  }
  const workingSchema = clone(schema);
  delete workingSchema.allOf;
  delete workingSchema.properties;
  workingSchema.properties = Object.fromEntries(settingsProps);
  return workingSchema;
};

const boxStylesOrder = [
  "width",
  "height",
  "margin",
  "padding",
  "backgroundColor",
  "borderColor",
  "borderWidth",
  "borderRadius",
  "borderGradient",
  "clipContent",
  // these have nested properties
  "backgroundImage",
  "backgroundGradient",
  "boxShadow",
];

// the list of text styles
const textStylesOrder = [
  "labelStyle",
  "floatingLabelStyle",
  "textStyle",
  "textScale",
];

// least important. Should be last on the order list
const otherInheritedStylesOrder = [
  "visible",
  "visibilityTransitionDuration",
  "textDirection",
  "alignment",
  "elevation",
  "elevationShadowColor",
  "elevationBorderRadius",
  "stackPositionTop",
  "stackPositionBottom",
  "stackPositionLeft",
  "stackPositionRight",
  "captureWebPointer",
];

export const defaultUiSchema: UiSchema = {
  // Submit on blur
  "ui:submitButtonOptions": {
    norender: true,
  },
  // show ID first, and form label/labelHint at the end
  "ui:order": [
    ...["id", "item-template", "*"],
    ...boxStylesOrder,
    ...textStylesOrder,
    ...["flex", "flexMode"],
    ...otherInheritedStylesOrder,
    ...["pullToRefresh"],
  ],
};

// generate the UiSchema based on the schema
// Note the original intention is to short-circuit and break out when hitting uiType since our schema is recursive
// This may not apply anymore.
export const generateUiSchema = (
  schema: JSONSchema7,
  shouldFlattenStyles: boolean,
): UiSchema => {
  const uiSchema: UiSchema = {
    ...defaultUiSchema,
  };

  const resolveRef = (
    schema: JSONSchema7,
    ref: string,
  ): JSONSchema7 | undefined => {
    const refPath = ref.replace("#/$defs/", "");
    return schema.$defs ? (schema.$defs[refPath] as JSONSchema7) : undefined;
  };

  const processProp = (
    propSchema: JSONSchema7,
    parentUiSchema: UiSchema,
    key: string,
  ) => {
    let uiType: string | undefined;
    // uiType can be defined inline
    if ("uiType" in propSchema) {
      uiType = propSchema.uiType as string;
      parentUiSchema[key] = {
        "ui:field": `${uiType}Field`,
        "ui:fieldReplacesAnyOrOneOf": true,
      };
    }
    // follow the $ref to look for uiType
    else if (propSchema.$ref) {
      const resolvedSchema = resolveRef(schema, propSchema.$ref as string);
      if (resolvedSchema) {
        if ("uiType" in resolvedSchema) {
          uiType = resolvedSchema.uiType as string;
          parentUiSchema[key] = {
            "ui:field": `${uiType}Field`,
            "ui:fieldReplacesAnyOrOneOf": true,
          };
        } else {
          // $ref can point to another $ref or another object
          processSchema(resolvedSchema, (parentUiSchema[key] ??= {}));
        }
      }
    }
    // else if the property is an object, recursively process it
    else if (propSchema.type === "object") {
      processSchema(propSchema, (parentUiSchema[key] ??= {}));
    }
    // arrays
    else if (propSchema.type === "array" && propSchema.items) {
      let itemSchema = propSchema.items as JSONSchema7;
      if (itemSchema.$ref) {
        const resolveSchema = resolveRef(schema, itemSchema.$ref);
        if (!resolveSchema) return;
        itemSchema = resolveSchema;
      }
      if ("uiType" in itemSchema) {
        const uiType = itemSchema.uiType as string;
        parentUiSchema[key] = {
          items: {
            "ui:field": `${uiType}Field`,
            "ui:fieldReplacesAnyOrOneOf": true,
          },
        };
      } else {
        parentUiSchema[key] = {
          items: {},
        };
        processSchema(itemSchema, parentUiSchema[key].items);
      }
    }
  };

  const processSchema = (
    currentSchema: JSONSchema7,
    currentUiSchema: UiSchema,
  ) => {
    // go through properties
    if (currentSchema.properties) {
      Object.keys(currentSchema.properties).forEach((key) => {
        const props = currentSchema.properties![key] as JSONSchema7;
        processProp(props, currentUiSchema, key);
      });
    }
    // go through additionalProperties
    if (
      currentSchema.additionalProperties &&
      typeof currentSchema.additionalProperties === "object"
    ) {
      const additionalProps = currentSchema.additionalProperties as JSONSchema7;
      processProp(additionalProps, currentUiSchema, "additionalProperties");
    }
  };

  // resolve the $ref the first time i.e. View { $ref: '#/$defs/View' })
  if (schema.$ref) {
    const resolvedSchema = resolveRef(schema, schema.$ref) ?? schema;
    processSchema(resolvedSchema, uiSchema);
  } else {
    processSchema(schema, uiSchema);
  }

  // style properties are under "style" node, but we flatten the schema
  // when displaying, so we need to flatten styles here too.
  // This means moving all styles' properties to the root
  if (shouldFlattenStyles && uiSchema.styles) {
    const updatedUiSchema = { ...uiSchema, ...uiSchema.styles };
    delete updatedUiSchema.styles;
    return updatedUiSchema;
  }
  return uiSchema;
};

export const getScreenWidgetUiSchema = () => {
  return {
    ...defaultUiSchema,
    onLoad: {
      "ui:field": "actionPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },
    inputs: {
      "ui:field": "inputsPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },
    onPullToRefresh: {
      "ui:field": "actionPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },
    onItemTap: {
      "ui:field": "actionPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },
    onTap: {
      "ui:field": "actionPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },
    "item-template": {
      template: {
        "ui:field": "widgetPicker",
        "ui:fieldReplacesAnyOrOneOf": true,
      },
    },
    events: {
      "ui:field": "eventsPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },
    body: {
      "ui:field": "widgetPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },
    testing: {
      "ui:field": "widgetPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },

    // we use sub schema so no nested 'styles'
    backgroundImage: {
      fallback: {
        "ui:field": "widgetPicker",
        "ui:fieldReplacesAnyOrOneOf": true,
      },
    },
    padding: {
      "ui:field": "paddingPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },
    margin: {
      "ui:field": "marginPicker",
      "ui:fieldReplacesAnyOrOneOf": true,
    },
  };
};
