import React, { useCallback, useContext, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Dropdown from "../../components/Dropdown";
import AppClone from "../../components/AppClone";
import { AppData } from "../../models/model";
import {
  EnsembleArtifactHistoryData,
  KeyValuePair,
} from "../../config/interfaces";
import { ArtifactHistoryDropdown } from "../ArtifactHistoryDropdown";
import { UpdateScreenLoadingContext } from "../../App";
import Modal from "../../components/Modal";
import CheckinModalContent from "../../components/CheckinModalContent";
import { useYamlDoc } from "../../hooks/useYamlDoc";

import "../../styles/breadcrumbs.sass";
import "./EditorHeader.sass";
import { ReactComponent as IconHome } from "../../assets/icon_home.svg";
import { ReactComponent as IconNext } from "../../assets/icon_next.svg";
import { ReactComponent as IconAlert } from "../../assets/icon_alert.svg";
import { useSchemas } from "../../hooks/useSchemas";
import iconSettings from "../../assets/icon_settings.svg";
import { HelpDropdown } from "./HelpDropdown";
import { ArtifactType } from "../../pages/EditorPage";
import { getArtifactPreviewUrl } from "../../utils";
import ScreenRename from "../../components/ScreenRename";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";

export type EditorHeaderProps = {
  app: AppData;
  editorContent: string;
  artifactId: string;
  artifactType: ArtifactType;
  inputParams: KeyValuePair[];
  isReadOnly: boolean;
  currentArtifactName: string;
  hasUnsavedChanges: boolean;
  isPreviewDisplayed: boolean;
  onSaveClick: () => void;
  onSetHomeScreen: () => void;
  selectedHistory?: EnsembleArtifactHistoryData;
  setSelectedHistory: (history?: EnsembleArtifactHistoryData) => void;
  isSourceView: boolean;
  onToggleSourceView: () => void;
  createScreenCopy: () => void;
  onTogglePreview: () => void;
  onCopyPreviewLink: () => void;
};

export const EditorHeader: React.FC<EditorHeaderProps> = ({
  app,
  editorContent,
  artifactId,
  artifactType,
  inputParams,
  isReadOnly,
  currentArtifactName,
  hasUnsavedChanges,
  isPreviewDisplayed,
  onSaveClick,
  onSetHomeScreen,
  selectedHistory,
  setSelectedHistory,
  isSourceView,
  onToggleSourceView,
  createScreenCopy,
  onTogglePreview,
  onCopyPreviewLink,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { updateDocContent, setDoc, setSelectedNode } = useYamlDoc();
  const [isCloneModalShowing, setIsCloneModalShowing] = useState(false);
  const [isSaveAsModalShowing, setIsSaveAsModalShowing] = useState(false);
  const [renameTrigger, setRenameTrigger] = useState<boolean>(false);
  const { isUpdateScreenLoading } = useContext(UpdateScreenLoadingContext);
  const schemaStore = useSchemas();

  const { propertyPanelEnabled } = useFeatureFlag();

  const screen = useMemo(
    () => app.screens?.find((screen) => screen.id === artifactId),
    [app.screens, artifactId],
  );

  const handleRename = useCallback(
    () => setRenameTrigger(true),
    [setRenameTrigger],
  );

  // Build list dropdown
  const peerArtifactList = useMemo(() => {
    const data = params.widget_id ? app.internalWidgets : app.screens;
    return data?.map((artifact) => {
      return {
        id: artifact.id,
        text: artifact.name,
        clickHandler: () => {
          setDoc(artifactType, null, null);
          setSelectedNode(undefined, null, []);
          setSelectedHistory(undefined);
          navigate(
            `/app/${app.id}/${params.widget_id ? "widget" : "screen"}/${
              artifact.id
            }`,
            { replace: true },
          );
        },
      };
    });
  }, [
    artifactType,
    app.id,
    app.internalWidgets,
    app.screens,
    navigate,
    params.widget_id,
    setDoc,
    setSelectedHistory,
    setSelectedNode,
  ]);

  const isSaveDisabled =
    (isUpdateScreenLoading || !hasUnsavedChanges) && !isReadOnly;

  const isNonHomeScreen =
    params?.screen_id !== undefined && // ensure a screen is opened (not a widget)
    !app?.screens?.find(
      (screen) => screen?.id === params.screen_id && screen?.isRoot,
    ); // screen must not already be a Home Screen

  // Build list of dropdown items
  const dropdownItems = useMemo(() => {
    const saveOptions = !isSaveDisabled
      ? [
          {
            id: "save",
            text: "Save",
            clickHandler: onSaveClick,
          },
          {
            id: "saveas",
            text: "Save as",
            clickHandler: () => setIsSaveAsModalShowing(!isSaveAsModalShowing),
          },
        ]
      : [];

    const commonOptions = [
      {
        id: "renameScreen",
        text: "Rename screen",
        clickHandler: handleRename,
      },
      {
        id: "makeacopy",
        text: "Make a copy",
        clickHandler: createScreenCopy,
      },
    ];

    const homeScreenOption = isNonHomeScreen
      ? [
          {
            id: "setHome",
            text: "Set as Home Screen",
            clickHandler: onSetHomeScreen,
          },
        ]
      : [];

    return [...saveOptions, ...homeScreenOption, ...commonOptions];
  }, [
    isSaveDisabled,
    isNonHomeScreen,
    isSaveAsModalShowing,
    onSaveClick,
    setIsSaveAsModalShowing,
    handleRename,
    createScreenCopy,
    onSetHomeScreen,
  ]);

  return (
    <div className="editor-header">
      <div className="breadcrumbs">
        <Link to="/">
          <IconHome className={"nav-icon"} />
        </Link>
        <IconNext className={"arrow-icon"} />
        <Link to={`/app/${app?.id}/screens`}>{app?.name}</Link>
        {params.screen_id && (
          <>
            <IconNext className={"arrow-icon"} />
            <Link to={`/app/${app?.id}/screens`}>Screens</Link>
          </>
        )}
        {params.widget_id && (
          <>
            <IconNext className={"arrow-icon"} />
            <Link to={`/app/${app?.id}/widgets`}>Widgets</Link>
          </>
        )}
        <IconNext className={"arrow-icon"} />
        <Dropdown
          activatorText={currentArtifactName}
          items={peerArtifactList}
        />
      </div>
      {!isReadOnly && (
        <div className="history-section">
          {selectedHistory && (
            <>
              {isSourceView && (
                <span
                  className="close-diff-text"
                  onClick={() => setSelectedHistory(undefined)}
                >
                  Close diff editor
                </span>
              )}
              <span
                className="close-diff-text"
                onClick={() => {
                  updateDocContent(
                    artifactType,
                    selectedHistory?.content,
                    schemaStore,
                  );
                  setSelectedHistory(undefined);
                }}
              >
                Restore version
              </span>
            </>
          )}
          {hasUnsavedChanges && (
            <div className="unsaved-changes">
              <IconAlert className="unsaved-changes-icon" />
              <p>You have unsaved changes</p>
            </div>
          )}
          {propertyPanelEnabled && (
            <button className="source-toggle" onClick={onToggleSourceView}>
              {isSourceView ? "Use Visual Editor (Beta)" : "Edit Source"}
            </button>
          )}
          <HelpDropdown />
          <ArtifactHistoryDropdown
            appId={app.id}
            artifactId={artifactId}
            onSelectHistory={setSelectedHistory}
            selectedHistory={selectedHistory}
          />
          <button
            disabled={isSaveDisabled}
            className={`checkin-button ${
              isSaveDisabled ? "disable-button" : ""
            }`}
            onClick={onSaveClick}
          >
            {isUpdateScreenLoading ? "Saving..." : "Save"}
          </button>
          <Dropdown
            wrapperClass="save-dropdown-wrapper"
            className={"save-dropdown"}
            listClass={`save-dropdown-list ${
              isNonHomeScreen && "set-home-screen"
            }`}
            activatorText=""
            items={dropdownItems}
          />
        </div>
      )}
      {/* App duplicate modal */}
      {isReadOnly && (
        <div className="history-section">
          <button className="source-toggle" onClick={onToggleSourceView}>
            {isSourceView ? "View Visual Editor" : "View Source"}
          </button>
          <HelpDropdown />
          {/* Save button */}
          <button
            className="checkin-button clone-button"
            onClick={() => setIsCloneModalShowing(true)}
          >
            Clone this app
          </button>
          <span className="clone-text">
            Clone to save and preview your changes.
          </span>
        </div>
      )}

      <AppClone
        app={app}
        displayCloneModal={isCloneModalShowing}
        setCloneTrigger={setIsCloneModalShowing}
      />

      <Modal
        isModalDisplayed={isSaveAsModalShowing}
        onHide={() => setIsSaveAsModalShowing(false)}
        headerText="Save as a new version"
        modalContent={
          <CheckinModalContent
            app={app}
            screenId={artifactId}
            editorContent={editorContent}
            onCancel={() => setIsSaveAsModalShowing(false)}
          />
        }
      />

      {isPreviewDisplayed ? null : (
        <Dropdown
          className="preview-action"
          listClass="options-dropdown-list"
          activatorText={
            <div className="button__preview">
              <img
                src={iconSettings}
                alt={"Preview"}
                height={18}
                title="Hide or change the device preview"
              />
              Preview
            </div>
          }
          hideCaret
          items={[
            {
              id: "preview-toggle",
              text: "Show preview",
              clickHandler: onTogglePreview,
            },
            {
              id: "preview-link",
              text: "Copy Preview Link",
              clickHandler: onCopyPreviewLink,
            },
            {
              id: "preview-options",
              text: "Open preview in new tab",
              clickHandler: () =>
                window.open(
                  getArtifactPreviewUrl(
                    app.id,
                    artifactId,
                    inputParams,
                    app.isReact,
                  ),
                  "_blank",
                ),
            },
          ]}
        />
      )}

      {screen && (
        <ScreenRename
          screen={screen}
          displayRenameModal={renameTrigger}
          setRenameTrigger={setRenameTrigger}
        />
      )}
    </div>
  );
};
