import React from "react";
import ReactTooltip from "react-tooltip";

import AssetsActionsMenu from "./AssetsActionsMenu";

// Assets
import default_screen_image from "../../assets/image_placeholder.svg";

// Interfaces
import { EnsembleAssetData, EnsembleFontData } from "../../config/interfaces";

export interface AssetsCardProps {
  asset: EnsembleAssetData | EnsembleFontData;
  isReadOnly?: boolean;
  assetType?: string;
}

const AssetsCard: React.FC<AssetsCardProps> = ({
  asset,
  isReadOnly,
  assetType,
}) => {
  const imageSrc =
    assetType === "font"
      ? default_screen_image
      : (asset as EnsembleAssetData).publicUrl ?? default_screen_image;

  return (
    <>
      <div className="screen-card assets">
        <div
          className="screen-card__asset-image"
          style={{ backgroundImage: `url(${imageSrc}` }}
        ></div>

        <div className="screen-card__content">
          <span>{asset.name}</span>

          {/* assets actions */}
          {!isReadOnly && (
            <AssetsActionsMenu
              asset={asset}
              isTemplate={false}
              isReadOnly={false}
              assetType={assetType ?? "asset"}
            />
          )}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </>
  );
};

export default AssetsCard;
