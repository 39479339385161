import React, { useState, useEffect, useRef, useCallback } from "react";
import { IDropdownItem } from "../config/interfaces";

interface IDropdownProps {
  activatorText?: string | React.ReactNode;
  items?: IDropdownItem[] | [];
  className?: string;
  wrapperClass?: string;
  listClass?: string;
  itemClass?: string;
  hideCaret?: boolean;
}

const Dropdown = ({
  activatorText = "",
  items = [],
  className = "",
  wrapperClass = "",
  listClass = "",
  itemClass = "",
  hideCaret = false,
}: IDropdownProps) => {
  const activatorRef = useRef<HTMLButtonElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const clicked = useRef(false);

  const handleClick = () => {
    if (clicked.current && !isOpen) {
      clicked.current = false;
    } else {
      setIsOpen((prevState) => !prevState);
      clicked.current = true;
    }
  };

  const handleClickOutside = useCallback(() => {
    clicked.current = true;
    if (isOpen) setIsOpen(false);
  }, [isOpen]);

  const keyHandler = (event: React.KeyboardEvent) => {
    if (isOpen) {
      switch (event.key) {
        case "Escape":
          setIsOpen(false);
          break;
        case "ArrowDown": {
          const nodeList = listRef.current!.querySelectorAll("a");
          if (activeIndex === items.length - 1) return;
          nodeList[activeIndex + 1].focus();
          break;
        }
        case "ArrowUp": {
          const nodeList2 = listRef.current!.querySelectorAll("a");
          if (activeIndex === 0) return;
          nodeList2[activeIndex - 1].focus();
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mouseup", handleClickOutside);
    } else {
      document.removeEventListener("mouseup", handleClickOutside);
      setTimeout(() => (clicked.current = false), 300);
    }
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setActiveIndex(-1);
    }
  }, [isOpen]);

  const focusHandler = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div className={`dropdown-wrapper ${wrapperClass}`} onKeyUp={keyHandler}>
      <button
        className={`${className} ${
          hideCaret ? "no-dropdown-icon" : "dropdown-button"
        }`}
        aria-haspopup="false"
        aria-controls="dropdown"
        onClick={handleClick}
        ref={activatorRef}
        type="button"
        onFocus={() => setActiveIndex(-1)}
      >
        {activatorText}
      </button>
      <ul
        id="dropdown"
        ref={listRef}
        role="list"
        style={{
          display: items.length === 0 ? "none" : isOpen ? "block" : "none",
        }}
        className={`${listClass}`}
      >
        {items.map((item, index) => (
          <li key={item.id}>
            <a
              href="#"
              onClick={item.clickHandler}
              onFocus={() => focusHandler(index)}
              className={`${itemClass}`}
            >
              {item.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
