/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppData } from "../models/model";
import React from "react";
import { Timestamp } from "firebase/firestore";

abstract class EnsembleBaseData {
  protected constructor(
    readonly id: string,
    readonly isArchived: boolean,
    readonly isRoot: boolean,
  ) {}
}

export class EnsembleTranslationData extends EnsembleBaseData {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly isArchived: boolean,
    readonly isRoot: boolean,
    readonly type: string,
    readonly content?: string,
    readonly defaultLocale?: boolean,
  ) {
    super(id, isArchived, isRoot);
  }
}

class EnsembleData extends EnsembleBaseData {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly isArchived: boolean,
    readonly isRoot: boolean,
    readonly content?: string,
    readonly isDraft?: boolean,
    readonly category?: string,
  ) {
    super(id, isArchived, isRoot);
  }

  toString(): string {
    return this.id + ", " + this.name;
  }
}

export class EnsembleScreenData extends EnsembleData {
  constructor(
    id: string,
    name: string,
    isArchived: boolean,
    isRoot: boolean,
    content?: string,
    isDraft?: boolean,
    category?: string,
    readonly label?: string,
    readonly comment?: string,
  ) {
    super(id, name, isArchived, isRoot, content, isDraft, category);
  }
}

export class EnsembleWidgetData extends EnsembleData {
  constructor(
    id: string,
    name: string,
    isArchived: boolean,
    isRoot: boolean,
    content?: string,
  ) {
    super(id, name, isArchived, isRoot, content);
  }
}

export class EnsembleAssetData {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly isArchived: boolean,
    readonly content: string,
    readonly publicUrl: string,
    readonly copyText: string,
  ) {}
}

export class EnsembleFontData {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly isArchived: boolean,
    readonly fontFamily: string,
    readonly fontType: string,
    readonly fontStyle: string,
    readonly fontWeight: string,
  ) {}
}

export class EnsembleThemeData {
  constructor(
    readonly content: string,
    readonly id: string,
    readonly isArchived: boolean,
  ) {}
}

export class EnsembleNewThemeData {
  constructor(
    readonly content: string,
    readonly isArchived: boolean,
  ) {}
}

// artifacts
export class EnsembleNewScreenData implements Omit<EnsembleData, "id"> {
  constructor(
    readonly name: string,
    readonly isRoot: boolean,
    readonly isArchived: boolean,
    readonly content?: string,
    readonly app_id?: string,
    readonly type?: string,
  ) {}
}

export class EnsembleArtifactHistoryData {
  constructor(
    readonly id: string,
    readonly content: string,
    readonly label: string,
    readonly comment: string,
    readonly updatedAt: Timestamp,
    readonly updatedBy: {
      name?: string;
    },
  ) {}
}

export class EnsembleCategoriesData {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly type: string,
    readonly isActive: boolean,
  ) {}
}

export interface IEnsembleUserData {
  auth_id: string;
  id: string;
  name: string | null;
  email: string | null;
  questionnaireAttempted: boolean | null;
  avatar: string | null;
  subscriptionPlan: {
    id: string;
    status: string;
    subscriptionPlanId: string;
    nextPayment: Timestamp;
    planDetails: ISubscriptionData;
  };

  user_apps?: [
    {
      id: string;
      app_role: string | null;
    },
  ];
}

export interface ICheckoutSession {
  data: {
    sessionUrl: string;
  };
}

export interface ICancelSubscription {
  data: {
    message: string;
    subscriptionPlan: any;
  };
}

export interface IUserSubscriptionPlan {
  id?: string;
  status: string;
  subscriptionPlanId?: string;
  nextPayment?: Timestamp;
}

export interface ISubscriptionData {
  name: string;
  price: string;
  currency: string;
  duration: string;
  features: Array<string>;
  stripeProductId: string;
}

export enum AppCategory {
  DEMO = "Demo",
}

export interface IFirebaseUser {
  uid: string;
  email: string | null;
  displayName: string | null;
}

export interface IDropdownItem {
  id: string;
  text: string | React.ReactNode;
  label?: string;
  comment?: string;
  clickHandler: () => void;
}

export interface KeyValuePair {
  key: string;
  value: string;
}

export interface IPreviewSize {
  width: number;
  height: number;
}

export interface AppCreationCategory {
  key: string;
  value: string;
}

export interface IAuthContext {
  currentUser: IEnsembleUserData | null;
  setCurrentUser?: any;
}

export interface IsUpdateScreenContext {
  isUpdateScreenLoading: boolean;
  setIsUpdateScreenLoading?: any;
}

export interface IAppDataContext {
  app: AppData | null;
  setAppData?: any;
  isAppReadOnly: boolean;
  setIsAppReadOnly?: any;
  isDemoApp: boolean;
  setIsDemoApp?: any;
  isPermissionChecked: boolean;
  setIsPermissionChecked?: any;
  resetAppData?: any;
}

export interface INavDataContext {
  appData: AppData | null;
  setAppData?: any;
}

export interface IAppInviteData {
  ref: string;
  appId: string;
  appName: string;
  userId: string;
  userName: string;
  inviteeEmail: string;
  inviteeAccessType: string;
}

export interface QuestionProps {
  title: string;
  options?: string[];
  allowOther?: boolean;
  type: "input" | "radio";
  onSubmit: (arg: string) => void;
}

export interface PublishingHistory {
  publishedTo: string;
  createdAt: Timestamp;
  publishedBy: string;
  publishingLog: string;
  role: string;
}
