import {
  isMap,
  isPair,
  isScalar,
  isSeq,
  Pair,
  Scalar,
  YAMLMap,
  YAMLSeq,
  Node,
  visit,
} from "yaml";
import { Path } from "ramda";
import { YAMLDocNode } from "../hooks/useYamlDoc";

/**
 * traverse the path to find the child node.
 * Note that if all the paths resolved but the value is not specify, a null (instead of undefined) will be returned.
 * This is to differentiate with the case where the paths don't resolve.
 */
export const find = (path: Path, node: Node): Node | null | undefined => {
  return path.reduce<Node | null | undefined>((currentNode, key) => {
    if (isMap(currentNode)) {
      if (currentNode.has(key)) {
        return (currentNode.get(key, true) as Node) || null;
      }
    } else if (isSeq(currentNode)) {
      if (
        typeof key === "number" &&
        key >= 0 &&
        key < currentNode.items.length
      ) {
        return (currentNode.items[key] as Node) || null;
      }
    }
  }, node as Node);
};

/**
 * get the key of this Node, applicable for Scalar, Pair and Map with a single Pair.
 * Use this to get a widget or action's name e.g. Text: { ... }
 */
export const getNodeName = (
  node: Node | Pair | undefined,
): string | undefined => {
  if (!node) return;

  if (isScalar(node)) {
    return node.value as string;
  } else if (isPair(node)) {
    return getPairKeyText(node);
  } else if (isMap(node) && node.items.length === 1) {
    return getPairKeyText(node.items[0]);
  }
};

// return the text representation of pair's key
export const getPairKeyText = (pair: Pair): string => {
  return (pair.key as Scalar).value?.toString() ?? "";
};

// add a new Pair to the parent
export const addValuePair = (parentPair: Pair, valuePair: Pair): void => {
  if (!isMap(parentPair.value)) {
    parentPair.value = new YAMLMap();
  }
  (parentPair.value as YAMLMap).items.push(valuePair);
};

export const updateNodeId = (node: YAMLDocNode, id: string | null): boolean => {
  if (!isPair(node)) return false;

  if (isMap(node.value)) {
    if (!id) {
      return (node.value as YAMLMap).delete("id");
    }
    (node.value as YAMLMap).set(new Scalar("id"), id);
  } else {
    node.value = new YAMLMap();
    if (id) {
      (node.value as YAMLMap).set(new Scalar("id"), id);
    }
  }
  return true;
};

export const removeNodeFromDirectParent = (
  parent: YAMLMap | YAMLSeq,
  node: YAMLDocNode,
): boolean => {
  if (isMap(parent)) {
    return (parent as YAMLMap).delete((node as Pair).key);
  }
  if (isSeq(parent)) {
    if (isPair(node)) {
      return parent.delete(node.key);
    } else if (isScalar(node)) {
      return parent.delete(node);
    }
  }
  return false;
};

export const removeNodeFromParent = (
  parent: Node,
  nodeToRemove: Node | Pair,
): boolean => {
  let didRemove = false;
  visit(parent, {
    Pair: (key, pair) => {
      if (pair === nodeToRemove) {
        didRemove = true;
        return visit.REMOVE;
      }
    },
    Map: (key, map) => {
      if (map === nodeToRemove) {
        didRemove = true;
        return visit.REMOVE;
      }
    },
    Scalar: (key, scalar) => {
      if (scalar === nodeToRemove) {
        didRemove = true;
        return visit.REMOVE;
      }
    },
  });
  return didRemove;
};
