import { NodeAction, useYamlDoc } from "../../hooks/useYamlDoc";
import { TreeView } from "./TreeView";
import React, { useEffect, useState } from "react";
import { NodeCategory, NodeRef, TreeItemPayload } from "./TreeNode";
import { ArtifactType } from "../../pages/EditorPage";
import { isMap, isScalar, Pair, Scalar, YAMLMap } from "yaml";
import { RemixIcon } from "../../components/Widgets";
import { ActionMenu } from "../../components/VisualEditor/ActionMenu";
import {
  generateAPIName,
  generateWidgetName,
  getKeyValue,
} from "../../utils/schemaUtils";
import { ScreenTypePicker } from "../../components/VisualEditor/ScreenTypePicker";
import { useEditor } from "../../hooks/useEditor";
import { useCustomWidgets } from "../../hooks/useCustomWidgets";
import { DocTopic, ExternalDoc } from "../../components/ExternalDoc";
import { WidgetItemPicker } from "../../components/VisualEditor/ItemPicker";
import { generatedDefaultValuesForWidgets } from "../WidgetPropertyPanel/utils/propertyPanelUtils";
import { Input } from "antd";
import { useTreeSearch } from "../../hooks/useTreeSearch";

const { Search } = Input;

export type TreePanelProps = {
  onNodeSelected: (node: NodeRef | null) => Promise<boolean>;
  artifactType: ArtifactType;
};

export const TreePanel: React.FC<TreePanelProps> = ({
  onNodeSelected,
  artifactType,
}) => {
  const {
    docTreeStore,
    doc,
    addNode,
    updateNode,
    addNodeByAction,
    insertRootNode,
    onAppCustomWidgetsChanged,
  } = useYamlDoc();
  const { setIsSourceView } = useEditor();
  const labelName = artifactType === ArtifactType.screen ? "SCREEN" : "WIDGET";

  const { appCustomWidgets } = useCustomWidgets();
  useEffect(() => {
    onAppCustomWidgetsChanged(appCustomWidgets);
  }, [appCustomWidgets, onAppCustomWidgetsChanged]);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredTreeData = useTreeSearch(docTreeStore ?? null, searchTerm);

  // the key for the selected node across all the trees
  const { selectedNode } = useYamlDoc();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const onSelect = (key: string | null, ref: NodeRef | null) => {
    // dispatch the selected node to the parent
    onNodeSelected(ref).then((success) => {
      if (success) {
        // tell all the trees to select or deselect its node
        setSelectedKey(key);
      }
    });
  };

  useEffect(() => {
    if (!selectedNode) {
      setSelectedKey(null);
    }
  }, [selectedNode]);

  // user picked a widget to be added to the tree
  const onWidgetSelectedAction = async (
    parentNode: YAMLMap,
    widgetKey: string,
    action: NodeAction,
    payload?: TreeItemPayload,
  ) => {
    const widgetRecord = await generatedDefaultValuesForWidgets(widgetKey);

    const widgetNode = doc!.createNode(widgetRecord);
    addNodeByAction(parentNode, widgetNode, action, payload);

    const widgetNodeKey: string | null = parentNode.items.reduce(
      (key: string | null, item) => {
        if (!key && isScalar(item.key) && item.key.value === "children") {
          const children = item.value as YAMLMap;
          key = `body-${children.items.length - 1}-${widgetKey}`;
        }
        return key;
      },
      null,
    );

    if (!widgetNode.items[0]) {
      return;
    }

    const widgetNodeRef: NodeRef = {
      node: widgetNode.items[0],
      parent: parentNode,
    };

    onSelect(widgetNodeKey, widgetNodeRef);
  };

  const onRootAction = (action: string) => {
    if (action === "View" || action === "ViewGroup") {
      insertRootNode(action);
    }
  };

  const onScreenAction = (action: string) => {
    if (action === "addWidget") {
      addNewWidget();
    } else if (action === "addAPI") {
      addNewAPI();
    } else if (action === "addScript") {
      addScript();
    }
  };

  // add a new custom widget to the tree
  const addNewWidget = () => {
    if (!doc?.contents) {
      return;
    }
    const newWidgetName = generateWidgetName(doc.contents as YAMLMap);
    const pair = doc.createPair(newWidgetName, {});
    if (pair) {
      addNode(doc.contents, pair);
      onSelect(`widget${newWidgetName}`, {
        node: pair,
        parent: null,
        category: "customWidget" as NodeCategory,
      });
    }
  };

  const addNewAPI = () => {
    if (!doc?.contents || !isMap(doc.contents)) {
      return;
    }
    const newAPIName = generateAPIName(doc.contents);

    const apiNode = doc.get("API");
    if (isMap(apiNode)) {
      const pair = doc.createPair(newAPIName, {});
      apiNode.items.push(pair);
      updateNode(apiNode);
      onSelect(`api${newAPIName}`, {
        node: pair,
        parent: apiNode,
        category: "api" as NodeCategory,
      });
    } else {
      const contents = doc.contents;
      if (isMap(contents)) {
        contents.items = contents.items.filter(
          (item) => getKeyValue(item) !== "API",
        );
      }
      const pair = doc.createPair<Scalar, YAMLMap>("API", { [newAPIName]: {} });
      addNode(doc.contents, pair);

      const node = pair.value?.items[0] as Scalar | Pair;
      onSelect(`api${newAPIName}`, {
        node: node,
        parent: null,
        category: "api" as NodeCategory,
      });
    }
  };

  const addScript = () => {
    if (!doc?.contents) {
      return;
    }
    const hasScript = doc.has("Global");
    if (hasScript) {
      return;
    }
    // don't change this line (hard to generate the correct syntax)
    const globalBlock = new Scalar(`// JavaScript code
`);
    const pair = doc.createPair<Scalar, Scalar>("Global", globalBlock);
    addNode(doc.contents, pair);

    onSelect("codeBlock", {
      node: pair,
      parent: null,
      category: "codeBlock" as NodeCategory,
    });
  };

  if (docTreeStore === undefined) {
    return <div></div>;
  }

  // Empty definition. need to select View//ViewGroup
  if (artifactType === ArtifactType.screen && !docTreeStore?.root) {
    // TODO: show ViewGroup in the tree
    if (docTreeStore?.viewGroup) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <span
            style={{ textAlign: "center", width: "200px", lineHeight: 1.5 }}
          >
            Use the{" "}
            <span
              style={{
                fontStyle: "italic",
                color: "#2BAADF",
                cursor: "pointer",
              }}
              onClick={() => setIsSourceView(true)}
            >
              Code Editor
            </span>{" "}
            to edit Screen Group
          </span>
        </div>
      );
    }
    return (
      <ScreenTypePicker
        onSelect={(screenType: string) => {
          onRootAction(screenType);
        }}
      />
    );
  }

  // To add new node in the View, e.g header, footer
  const addNodeInView = (category: NodeCategory) => {
    if (!doc?.contents) {
      return;
    }

    const newPair = doc.createPair(category, {});
    const viewNode = doc.get("View") as YAMLMap;

    addNode(viewNode, newPair);

    onSelect(category, {
      node: newPair,
      parent: viewNode,
      category,
    });
  };

  return (
    <div className={"tree-panel"}>
      <Search
        placeholder="Search..."
        style={{
          marginBottom: "16px",
        }}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className={"tree-block"}>
        <div className={"tree-block-header-with-separator"}>
          {labelName}
          {/* Screen's ACTIONS */}
          {artifactType === ArtifactType.screen && (
            <div className={"screen-action-menu-anchor"}>
              <ActionMenu
                anchor={
                  <button>
                    <span>Actions</span>
                    <RemixIcon name={"arrow-down-s-line"} />
                  </button>
                }
                items={[
                  {
                    iconName: "add-circle-line",
                    label: "Add Widget",
                    value: "addWidget",
                    description: (
                      <div>
                        <div>
                          Add a custom widget that can be reused within this
                          screen
                        </div>
                        <ExternalDoc topicName={DocTopic.customWidgets} />
                      </div>
                    ),
                  },
                  {
                    iconName: "database-2-line",
                    label: "Add API",
                    value: "addAPI",
                    description:
                      "Add an API to fetch data from a remote server",
                  },
                  {
                    iconName: "braces-line",
                    label: "Add Script",
                    value: "addScript",
                    description:
                      "Add a Javascript code block to execute when the screen loads",
                  },
                ]}
                onSelect={onScreenAction}
              />
            </div>
          )}
        </div>
        <div className={"tree-block-body"}>
          <TreeView
            treeData={filteredTreeData?.root ?? []}
            onSelect={onSelect}
            selectedKey={selectedKey}
            searchKey={searchTerm}
          />
          {artifactType === ArtifactType.screen && (
            <>
              {filteredTreeData?.header ? (
                <TreeView
                  treeData={filteredTreeData?.header ?? []}
                  onSelect={onSelect}
                  selectedKey={selectedKey}
                  searchKey={searchTerm}
                />
              ) : (
                <TreePlaceholder
                  label="Set a header"
                  tooltip="Add a header for this screen"
                  onClick={() => addNodeInView(NodeCategory.Header)}
                />
              )}

              {filteredTreeData?.footer ? (
                <TreeView
                  treeData={filteredTreeData?.footer ?? []}
                  onSelect={onSelect}
                  selectedKey={selectedKey}
                  searchKey={searchTerm}
                />
              ) : (
                <TreePlaceholder
                  label="Set a footer"
                  tooltip="Add a footer for this screen"
                  onClick={() => addNodeInView(NodeCategory.Footer)}
                />
              )}
            </>
          )}
        </div>
      </div>

      <div className={"tree-block"}>
        <div className={"tree-block-header"}>{labelName} BODY</div>
        {docTreeStore?.body ? (
          <div className={"tree-block-body"}>
            <TreeView
              treeData={filteredTreeData?.body}
              onSelect={onSelect}
              onWidgetSelectedAction={onWidgetSelectedAction}
              selectedKey={selectedKey}
              expandFirstNode={true}
              searchKey={searchTerm}
              draggable={true}
            />
          </div>
        ) : (
          docTreeStore?.root &&
          docTreeStore.root.length > 0 && (
            <WidgetItemPicker
              trigger={
                <div className={"set-body-placeholder-large"}>
                  <div>
                    <RemixIcon
                      name={"layout-masonry-line"}
                      style={{ fontSize: 50 }}
                    />
                  </div>
                  <span>Set a body widget</span>
                </div>
                // <div className={"set-screen-or-widget-body"}>
                //   Set Body Widget
                // </div>
              }
              onItemSelect={(widgetKey) => {
                onWidgetSelectedAction(
                  docTreeStore!.root![0].ref.parent as YAMLMap,
                  widgetKey,
                  NodeAction.SetRootBody,
                );
              }}
            />
          )
        )}
      </div>

      {docTreeStore?.customWidgets && artifactType === ArtifactType.screen && (
        <div className={"tree-block"}>
          <div className={"tree-block-header-with-separator"}>
            WIDGETS
            <div
              onClick={addNewWidget}
              className={"action-icon tooltip-container"}
            >
              <RemixIcon name={"add-line"} />
              <div className={"tooltip"}>Add a widget</div>
            </div>
          </div>
          <div className={"tree-block-body"}>
            <TreeView
              treeData={filteredTreeData?.customWidgets}
              onSelect={onSelect}
              onWidgetSelectedAction={onWidgetSelectedAction}
              selectedKey={selectedKey}
              searchKey={searchTerm}
              draggable={true}
            />
          </div>
        </div>
      )}

      {docTreeStore?.api && artifactType === ArtifactType.screen && (
        <div className={"tree-block"}>
          <div className={"tree-block-header-with-separator"}>
            API
            <div
              onClick={addNewAPI}
              className={"action-icon tooltip-container"}
            >
              <RemixIcon name={"add-line"} />
              <div className={"tooltip"}>Add an API</div>
            </div>
          </div>
          <div className={"tree-block-body"}>
            <TreeView
              treeData={filteredTreeData?.api}
              onSelect={onSelect}
              selectedKey={selectedKey}
              searchKey={searchTerm}
            />
          </div>
        </div>
      )}

      {docTreeStore?.codeBlock && artifactType === ArtifactType.screen && (
        <div className={"tree-block"}>
          <div className={"tree-block-header-with-separator"}>SCRIPT</div>
          <div className={"tree-block-body"}>
            <TreeView
              treeData={filteredTreeData?.codeBlock}
              onSelect={onSelect}
              selectedKey={selectedKey}
              searchKey={searchTerm}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const TreePlaceholder = ({
  onClick,
  label,
  tooltip,
}: {
  onClick: () => void;
  label: string;
  tooltip: string;
}) => (
  <div className="set-tree-placeholder" onClick={onClick}>
    <div>
      <RemixIcon name="layout-masonry-line" />
    </div>
    <span>{label}</span>
    <div className="action-icon tooltip-container">
      <RemixIcon name="add-line" />
      <div className="tooltip">{tooltip}</div>
    </div>
  </div>
);
