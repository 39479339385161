import {
  EnsembleScreenData,
  EnsembleTranslationData,
} from "../config/interfaces";

interface LanguageDetail {
  name: string;
  flag: string;
}
export const languageMap: Record<string, LanguageDetail> = {
  ar: { name: "Arabic", flag: "🇸🇦" },
  bn: { name: "Bengali", flag: "🇧🇩" },
  de: { name: "German", flag: "🇩🇪" },
  en: { name: "English", flag: "🇺🇸" },
  es: { name: "Spanish", flag: "🇪🇸" },
  fr: { name: "French", flag: "🇫🇷" },
  hi: { name: "Hindi", flag: "🇮🇳" },
  id: { name: "Indonesian", flag: "🇮🇩" },
  it: { name: "Italian", flag: "🇮🇹" },
  ja: { name: "Japanese", flag: "🇯🇵" },
  jv: { name: "Javanese", flag: "🇮🇩" },
  ko: { name: "Korean", flag: "🇰🇷" },
  ms: { name: "Malay", flag: "🇲🇾" },
  nl: { name: "Dutch", flag: "🇳🇱" },
  pa: { name: "Punjabi", flag: "🇮🇳" },
  pl: { name: "Polish", flag: "🇵🇱" },
  pt: { name: "Portuguese", flag: "🇵🇹" },
  ro: { name: "Romanian", flag: "🇷🇴" },
  ru: { name: "Russian", flag: "🇷🇺" },
  sv: { name: "Swedish", flag: "🇸🇪" },
  ta: { name: "Tamil", flag: "🇮🇳" },
  te: { name: "Telugu", flag: "🇮🇳" },
  th: { name: "Thai", flag: "🇹🇭" },
  tr: { name: "Turkish", flag: "🇹🇷" },
  uk: { name: "Ukrainian", flag: "🇺🇦" },
  ur: { name: "Urdu", flag: "🇵🇰" },
  vi: { name: "Vietnamese", flag: "🇻🇳" },
  zh: { name: "Chinese", flag: "🇨🇳" },
  el: { name: "Greek", flag: "🇬🇷" },
  da: { name: "Danish", flag: "🇩🇰" },
};

export const getTranslationLanguageFlag = (languageCode: string): string => {
  return languageMap[languageCode]?.flag ?? "🇺🇳";
};

export const getTranslationLanguageName = (languageCode: string): string => {
  return languageMap[languageCode]?.name ?? languageCode.toUpperCase();
};

export const getLanguageCode = (language: EnsembleTranslationData): string => {
  return language.id.replace("i18n_", "");
};

export const convertFileToBase64String = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () =>
      resolve(reader.result?.toString().split(",")[1] as string);
    reader.onerror = (error) => reject(error);
  });
};

export const removeFlutterLocalStorageArtifacts = (
  screens: EnsembleScreenData[],
) => {
  try {
    screens.forEach((screen) => {
      localStorage.removeItem("flutter." + screen.id);
    });
  } catch (error) {
    console.error("Error cleaning up local storage: ", error);
  }
};
