import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../App";
import { useAppContext } from "./AppPagesWrapper";
import { head } from "ramda";

// Components
import { EditorV2 } from "../containers/EditorV2/EditorV2";

// APIs
import {
  useCreateScreens,
  useGetScreenDetails,
  useUpdateScreen,
  useUpdateWidget,
} from "../hooks/useAPIs";
import { useCreateWidget } from "../hooks/useFirebase";
import { getScreenDetailsWithSnapShot } from "../hooks/useFirebase";

// Interfaces
import { EnsembleScreenData } from "../config/interfaces";

import { toastOptions } from "../config/constants";
import { toast } from "react-toastify";
import { FormRefProvider } from "../models/FormRefContext";
import { removeFlutterLocalStorageArtifacts } from "../utils/Util";

export enum ArtifactType {
  i18n = "i18n",
  theme = "theme",
  asset = "asset",
  screen = "screen",
  widget = "widget",
  font = "font",
  internal_widget = "internal_widget",
  internal_script = "internal_script",
}

export const EditorPage: React.FC = () => {
  const params = useParams();
  const { app, isAppReadOnly } = useAppContext();
  const { currentUser } = useContext(AuthContext);

  const [screenDetails, setScreenDetails] = useState<EnsembleScreenData>({
    id: "",
    name: "",
    isArchived: true,
    isRoot: false,
  });

  const artifactId = params.screen_id ?? params.widget_id;
  const isScreenEditor = !!params.screen_id;

  useEffect(() => {
    getScreenDetailsWithSnapShot(
      app.id,
      artifactId,
      isScreenEditor ? "artifacts" : "internal_artifacts",
      (res: EnsembleScreenData) => {
        setScreenDetails(res);
        document.title = res?.name + " | " + app.name;
      },
    );
    // handle flutter artifacts cleanup on page unload
    const removeAppArtifacts = () => {
      if (app.screens) removeFlutterLocalStorageArtifacts(app.screens);
    };
    window.addEventListener("beforeunload", removeAppArtifacts);

    return () => {
      // handle flutter artifacts cleanup on component unmount
      removeAppArtifacts();
      window.removeEventListener("beforeunload", removeAppArtifacts);
    };
  }, [app.id, app.name, app.screens, artifactId, isScreenEditor]);

  const screenDetailsQuery = useGetScreenDetails(
    app.id,
    artifactId,
    isScreenEditor ? "artifacts" : "internal_artifacts",
  );
  const screen = screenDetailsQuery.data?.screen;

  const updateScreenQuery = useUpdateScreen(
    currentUser,
    app.id,
    artifactId ?? "",
  );

  const updateWidgetQuery = useUpdateWidget(
    currentUser,
    app.id,
    artifactId ?? "",
  );

  const createScreenQuery = useCreateScreens(currentUser, app.id, [
    {
      name: screenDetailsQuery.data?.screen?.name + " copy",
      app_id: app.id,
      content: screen?.content,
      isRoot: false,
      isArchived: false,
    },
  ]);

  const createWidgetQuery = useCreateWidget(currentUser, app.id, {
    id: "",
    name: screenDetailsQuery.data?.screen?.name + "Copy",
    content: screen?.content,
    isArchived: false,
    isRoot: false,
  });

  useEffect(() => {
    if (screenDetailsQuery.isSuccess)
      document.title = app.name + " | " + screenDetailsQuery.data.screen?.name;
  });

  const onSave = useCallback(
    (content: string) => {
      if (isScreenEditor) updateScreenQuery.mutate({ content });
      else updateWidgetQuery.mutate({ content });
    },
    [isScreenEditor, updateScreenQuery, updateWidgetQuery],
  );

  const createScreenCopy = () => {
    if (screenDetailsQuery.isSuccess) {
      if (screenDetailsQuery.data) {
        toast.loading(
          `copying this ${isScreenEditor ? "screen" : "widget"}...`,
          {
            position: "top-right",
            type: toast.TYPE.INFO,
            theme: "dark",
            autoClose: false,
          },
        );

        isScreenEditor
          ? createScreenQuery.mutate()
          : createWidgetQuery.mutate();
      }
    }
  };
  useEffect(
    () => {
      if (createScreenQuery.isLoading || createWidgetQuery.isLoading) return;
      else if (createScreenQuery.isSuccess || createWidgetQuery.isSuccess) {
        toast.dismiss();
        const newId = isScreenEditor
          ? head(createScreenQuery.data?.insert_screen?.returning || [])?.id
          : head(createWidgetQuery.data?.insert_screen?.returning || [])?.id;

        if (newId) {
          const newTab = window.open(
            `/app/${app.id}/${isScreenEditor ? "screen" : "widget"}/${newId}`,
            "_blank",
          );
          if (newTab) newTab.focus();
        } else
          toast.error("Something went wrong. Please try again.", toastOptions);
      } else if (createScreenQuery.isError || createWidgetQuery.isError) {
        toast.dismiss();
        toast.error("An error occurred...", toastOptions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      app.id,
      createScreenQuery.data?.insert_screen?.returning,
      createScreenQuery.isError,
      createScreenQuery.isLoading,
      createScreenQuery.isSuccess,
      createScreenQuery.status,
    ],
  );

  if (!screenDetails?.id) {
    return <></>;
  }

  return (
    <FormRefProvider>
      <EditorV2
        key={artifactId}
        isReadOnly={isAppReadOnly}
        artifact={screenDetails}
        onSave={onSave}
        onScreenCopy={createScreenCopy}
        artifactType={
          params.widget_id ? ArtifactType.widget : ArtifactType.screen
        }
        app={app}
      />
    </FormRefProvider>
  );
};
